import request from "@/libs/utils/request";

export const getEventConf=(group_id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/tag_event/${group_id}/conf`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data.conf);
    }).catch((e) => reject(e));
});

export const getEventProviderConfs=(group_id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/tag_event/${group_id}/provider_confs`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const postEventConf=(group_id:string, {analytics_id}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/tag_event/${group_id}/conf`,
        method: 'post',
        data:{analytics_id}
    }).then((response: any) => {
        resolve(response.data.conf);
    }).catch((e) => reject(e));
});

export const postEventProviderConf=(messenger_id:string, {conf_arr}):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/tag_event/${messenger_id}/provider_conf`,
        method: 'post',
        data:{conf_arr}
    }).then((response: any) => {
        resolve(response.data.conf);
    }).catch((e) => reject(e));
});