<template>
  <el-drawer
      :title="$t('care.messenger.setting')"
      :visible.sync="centervisible"
      @close="onClose"
      size="90%">
    <div style="height: 100%;padding: 30px">
      <el-dialog
          :title="edit_messenger_id!==null?$t('care.messenger.edit_messenger'):$t('care.messenger.add_messenger')"
          :visible.sync="addMessengerVisible"
          append-to-body>
        <a-spin :spinning="add_messenger_loading" :tip="$t('res.waba.loading_tip')">
          <el-form :model="addMessengerRow"  :rules="rules_add_messenger" :label-width="formLabelWidth" style="max-width: 800px">
            <el-form-item v-if="edit_messenger_id===null" :label="$t('res.waba.auth')">
              <div class="formval">

                <a-button  type="primary" v-if="addMessengerRow.provider==='whatsapp:waba-channel-service'" @click.prevent.stop="onOpenWabaAuth" :class="['auth_btn',edit_messenger_id?'auth_btn_disable':'']">{{$t('res.waba.manage_waba')}} <i class="el-icon-right"></i></a-button>
                <a-button  type="primary" v-else-if="addMessengerRow.provider==='fbcomment'"  @click.prevent.stop="onOpenFacebookAuth" :class="['auth_btn',edit_messenger_id?'auth_btn_disable':'']">{{$t('res.waba.manage_auth_account')}} <i class="el-icon-right"></i></a-button>
                <a-button  type="primary" v-else  @click.prevent.stop="onOpenThirdAuth" :class="['auth_btn',edit_messenger_id?'auth_btn_disable':'']">{{$t('res.waba.manage_facebook_app')}} <i class="el-icon-right"></i></a-button>

                <!--              <el-checkbox style="margin-right: 10px" v-model="addMessengerRow.private_checked"> </el-checkbox>-->
                <!--              {{$t('care.messenger.terms.title1')}}-->
                <!--              <a style="margin: 0 4px;" target="_blank" href="https://yme.top/privacypolicy">{{$t('care.messenger.terms.title2')}}</a> {{$t('care.messenger.terms.title3')}}-->
                <!--              <a style="margin: 0 4px;" target="_blank" href="https://account.topkee.com/site/terms.html?type=USER_AGREEMENT&lang=zh-TW">{{$t('care.messenger.terms.title4')}}</a>-->
              </div>
            </el-form-item>
            <el-form-item :label="$t('care.messenger.provider')" prop="provider" >
              <div class="formval">
                <el-select style="min-width: 395px" :disabled="edit_messenger_id!==null" v-model="addMessengerRow.provider" :placeholder="$t('care.messenger.provider')">
                  <el-option v-if="provider_whatsapp_visiable" label="WhatsApp" value="whatsapp"></el-option>
                  <el-option label="Whatsapp Business Account" value="whatsapp:waba-channel-service"></el-option>
                  <el-option label="Messenger" value="messenger"></el-option>
                  <el-option label="Facebook Comment" value="fbcomment"></el-option>
                </el-select>
              </div>
            </el-form-item>

            <el-form-item v-if="addMessengerRow.provider==='whatsapp:waba-channel-service'"  :label="$t('care.messenger.channel')" prop="channel_id" >
              <div class="formval">
                <el-select class="waba_select"  :disabled="edit_messenger_id!==null" style="min-width: 395px" v-model="addMessengerRow.channel_id" :placeholder="$t('care.messenger.channel')">
                  <el-option :label="channel.display_name" v-for="channel in  wabachannels" :key="channel.id" :value="channel.id"></el-option>

                </el-select>

              </div>
            </el-form-item>
            <el-form-item v-if="addMessengerRow.provider==='fbcomment'"  :label="$t('care.messenger.auth_account')" prop="channel_id" >
              <div class="formval">
                <el-select class="waba_select"   style="min-width: 395px"  :disabled="edit_messenger_id!==null" v-model="addMessengerRow.auth_id" :placeholder="$t('care.messenger.auth_account')">
                  <el-option :key="token.id" v-for="token in fb_comment_auths" :label="token.email" :value="token.id"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item v-if="addMessengerRow.provider==='fbcomment'"  :label="$t('care.messenger.page_id')" prop="channel_id" >
              <div class="formval">
                <el-select class="waba_select"  :disabled="edit_messenger_id!==null" style="min-width: 395px" v-model="addMessengerRow.channel_id" :placeholder="$t('care.messenger.page_id')">
                  <el-option :label="channel.id+'|'+channel.name" v-for="channel in  fbcomment_channels" :key="channel.id" :value="channel.id"></el-option>
                </el-select>
              </div>
            </el-form-item>

            <el-form-item v-if="addMessengerRow.provider==='whatsapp'" :label="$t('care.messenger.bm')" prop="business_id" >
              <div class="formval">
                <el-select  :disabled="edit_messenger_id!==null" v-model="addMessengerRow.business_id" :placeholder="$t('care.messenger.bm')">
                  <el-option :key="biz.id" v-for="biz in bizs" :label="biz.business_name" :value="biz.business_id"></el-option>
                </el-select>
                <el-button  :disabled="edit_messenger_id!==null" style="width: 150px;margin-left: 10px"  class="button-new-tag"  @click="onAddBiz">{{$t('care.messenger.addbm')}}</el-button>
              </div>
            </el-form-item>

            <el-form-item v-if="addMessengerRow.provider==='whatsapp'||addMessengerRow.provider==='messenger'"  :label="$t('care.messenger.facebook_app')" prop="auth_id" >
              <div class="formval">


                <el-select class="waba_select"   style="min-width: 395px"  :disabled="edit_messenger_id!==null" v-model="addMessengerRow.auth_id" :placeholder="$t('care.messenger.facebook_app')">
                  <el-option :key="token.id" v-for="token in tokens" :label="token.tag" :value="token.id"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item v-if="addMessengerRow.provider==='whatsapp'"  :label="$t('care.messenger.phone')" prop="phone_number_id" >
              <div class="formval">
                <el-select  style="min-width: 395px"  :disabled="edit_messenger_id!==null" v-model="addMessengerRow.phone_number_id" :placeholder="$t('care.messenger.phone')">
                  <el-option :key="phoneObj.id" v-for="phoneObj in whatsappPhones" :label="phoneObj.display_phone_number" :value="phoneObj.id"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item v-if="addMessengerRow.provider==='messenger'"  :label="$t('care.messenger.page_id')" prop="page_id" >
              <div class="formval">
                <el-select  style="min-width: 395px"  :disabled="edit_messenger_id!==null" v-model="addMessengerRow.page_id" :placeholder="$t('care.messenger.page_id')">
                  <el-option :key="phoneObj.id" v-for="phoneObj in fbpages" :label="phoneObj.id+'|'+phoneObj.name" :value="phoneObj.id"></el-option>
                </el-select>
              </div>
            </el-form-item>

            <el-form-item  :label="$t('care.messenger.name')" prop="name" >
              <div class="formval">
                <el-input  style="width: 395px" :disabled="edit_messenger_id!==null"  v-model="addMessengerRow.name"></el-input>
              </div>
            </el-form-item>
            <el-form-item v-if="edit_messenger_id!==null" :label="$t('care.messenger.enable')" prop="enable" >
              <div class="formval">

                <a-switch @change="onChangeEnable" :checked-children="$t('care.messenger.enable_on')" :un-checked-children="$t('care.messenger.enable_off')" v-model:checked="addMessengerRow.enable" />
              </div>
            </el-form-item>
            <el-form-item v-if="edit_messenger_id!==null&&edit_messenger!==null&&edit_messenger.provider==='whatsapp'" :label="$t('care.messenger.tpl_setting')">
              <div class="formval">
                <a-button type="primary" @click="handleTpl(edit_messenger)" >{{$t('care.messenger.tpl_setting')}}  <i class="el-icon-right"></i></a-button>
              </div>
            </el-form-item>

          </el-form>
        </a-spin>
        <div slot="footer" class="dialog-footer">
          <el-button v-if="edit_messenger_id===null" @click="addMessengerVisible = false">{{$t('care.messenger.cancel')}}</el-button>
          <el-button type="primary" @click="addMessenger">{{$t('care.messenger.sure')}}</el-button>
        </div>
      </el-dialog>

      <el-dialog
          width="1100"
          title="Third Auth"
          :visible.sync="thirdAuthVisible"
          @close="onProvider"
          append-to-body>
        <Iframe style="height: 1000px;width: 100%" frameborder="0" :src="third_auth_src"></Iframe>
      </el-dialog>

      <el-dialog
          width="1100"
          title="Facebook Auth"
          :visible.sync="facebookAuthVisible"
          @close="onProvider"
          append-to-body>
        <Iframe style="height: 1000px;width: 100%" frameborder="0" :src="auth_facebook_auth_src"></Iframe>
      </el-dialog>

      <el-dialog
          custom-class="waba"
          width="1100"
          :visible.sync="authWabaVisible"
          @close="onProvider"
          append-to-body>
        <template #title>
        </template>

        <Iframe style="height: 1000px;width: 100%" frameborder="0" :src="auth_waba_src"></Iframe>
      </el-dialog>

      <el-dialog
          :title="$t('care.messenger.add_biz')"
          :visible.sync="addBizVisible"
          append-to-body>
        <el-form :model="addBizRow" :rules="rules_add_biz" :label-width="formLabelWidth" style="max-width: 800px">
          <el-form-item :label="$t('care.messenger.business_id')" prop="business_id">
            <div class="formval">
              <el-input  v-model="addBizRow.business_id"></el-input>
            </div>
          </el-form-item>
          <el-form-item :label="$t('care.messenger.business_name')" prop="business_name">
            <div class="formval">
              <el-input  v-model="addBizRow.business_name"></el-input>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addBizVisible = false">{{$t('care.messenger.cancel')}}</el-button>
          <el-button type="primary" @click="addBiz">{{$t('care.messenger.sure')}}</el-button>
        </div>
      </el-dialog>

      <el-dialog
          :title="$t('care.settingscript.title')"
          :visible.sync="settingScriptVisible"
          append-to-body>
        <div style="display: flex;flex-direction: row;width: 100%;">
          <el-form :model="embedOption"  :rules="rules_settingscript" :label-width="formLabelWidth2" style="max-width: 800px">

            <el-form-item :label="$t('care.settingscript.messenger_show_level')" prop="messenger_show_level" >
              <div class="formval">
                <el-select v-model="embedOption.position.messenger_show_level" :placeholder="$t('care.settingscript.messenger_show_level')">
                  <el-option :label="$t('care.settingscript.messenger_show_level_val.level1')" value="level1"></el-option>
                  <el-option :label="$t('care.settingscript.messenger_show_level_val.level2')" value="level2"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item :label="$t('care.settingscript.frame_width')" prop="frame_width" >
              <div class="formval">
                <el-input-number :min="100" :max="600" v-model="embedOption.position.frame_width" :step="10"></el-input-number>
                <strong>{{$t('care.settingscript.unit_px')}}</strong>
              </div>
            </el-form-item>

            <el-form-item :label="$t('care.settingscript.frame_height')" prop="frame_height" >
              <div class="formval">
                <el-input-number :min="0" :max="100" v-model="embedOption.position.frame_height" :step="10"></el-input-number>
                <strong>{{$t('care.settingscript.unit_persent')}}</strong>
              </div>
            </el-form-item>

            <el-form-item :label="$t('care.settingscript.btn_size')" prop="btn_size" >
              <div class="formval">
                <el-select v-model="embedOption.position.btn_size" :placeholder="$t('care.settingscript.btn_size')">
                  <el-option :label="$t('care.settingscript.selectval.large')" value="large"></el-option>
                  <el-option :label="$t('care.settingscript.selectval.normal')" value="normal"></el-option>
                  <el-option :label="$t('care.settingscript.selectval.small')" value="small"></el-option>
                </el-select>
              </div>
            </el-form-item>
            <el-form-item :label="$t('care.settingscript.btn_size_mobile')" prop="btn_size_mobile" >
              <div class="formval">
                <el-select v-model="embedOption.position.btn_size_mobile" :placeholder="$t('care.settingscript.btn_size_mobile')">
                  <el-option :label="$t('care.settingscript.selectval.large')" value="large"></el-option>
                  <el-option :label="$t('care.settingscript.selectval.normal')" value="normal"></el-option>
                  <el-option :label="$t('care.settingscript.selectval.small')" value="small"></el-option>
                </el-select>
              </div>
            </el-form-item>

            <el-form-item :label="$t('care.settingscript.side')" prop="side" >
              <div class="formval">
                <el-select v-model="embedOption.position.side" :placeholder="$t('care.settingscript.side')">
                  <el-option :label="$t('care.settingscript.selectval.left')" value="left"></el-option>
                  <el-option :label="$t('care.settingscript.selectval.right')" value="right"></el-option>
                </el-select>
              </div>
            </el-form-item>

            <el-form-item :label="$t('care.settingscript.bottom')" prop="bottom" >
              <div class="formval">
                <el-input-number :min="0" :max="100" v-model="embedOption.position.bottom" :step="10"></el-input-number>
                <strong>{{$t('care.settingscript.unit_px')}}</strong>
              </div>
            </el-form-item>

            <el-form-item :label="$t('care.settingscript.bottom_mobile')" prop="bottom_mobile" >
              <div class="formval">
                <el-input-number :min="0" :max="100" v-model="embedOption.position.bottom_mobile" :step="10"></el-input-number>
                <strong>{{$t('care.settingscript.unit_px')}}</strong>
              </div>
            </el-form-item>

            <el-form-item v-if="embedOption.position.side==='left'" :label="$t('care.settingscript.left')" prop="left" >
              <div class="formval">
                <el-input-number :min="0" :max="100" v-model="embedOption.position.left" :step="10"></el-input-number>
                <strong>{{$t('care.settingscript.unit_px')}}</strong>
              </div>
            </el-form-item>

            <el-form-item v-if="embedOption.position.side==='right'" :label="$t('care.settingscript.right')" prop="right" >
              <div class="formval">
                <el-input-number :min="0" :max="100" v-model="embedOption.position.right" :step="10"></el-input-number>
                <strong>{{$t('care.settingscript.unit_px')}}</strong>
              </div>
            </el-form-item>

            <el-form-item :label="$t('care.settingscript.show_qrcode')" prop="show_qrcode" >
              <div class="formval">
                <el-select v-model="embedOption.position.show_qrcode" :placeholder="$t('care.settingscript.show_qrcode')">
                  <el-option :label="$t('care.settingscript.show_qrcode_on')" :value="1"></el-option>
                  <el-option :label="$t('care.settingscript.show_qrcode_off')" :value="0"></el-option>
                </el-select>
              </div>
            </el-form-item>

          </el-form>
          <div style="display: flex;justify-content: center;align-items: center;flex: 1;">
            <a target="_blank" :href="'/embed/test.html?group_id='+(editRow?editRow.id:'')">
              {{$t('care.messenger.view')}}
            </a>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="settingScriptVisible = false">{{$t('care.messenger.close')}}</el-button>
        </div>
      </el-dialog>

      <el-dialog
          :title="addDomainRow.id?$t('care.trusted_domain.edit'):$t('care.trusted_domain.add')"
          :visible.sync="addDomainVisible"
          width="500px"
          append-to-body>
        <el-form :model="addDomainRow" :label-width="formLabelWidth" style="max-width: 400px">
          <el-form-item :label="$t('care.trusted_domain.domain')" prop="domain" required>
            <div class="formval">
              <el-input placeholder="your_website.com" v-model="addDomainRow.domain"></el-input>
            </div>
          </el-form-item>
          <el-form-item :label="$t('care.trusted_domain.name')" prop="name">
            <div class="formval">
              <el-input  v-model="addDomainRow.name"></el-input>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addDomainVisible = false">{{$t('care.messenger.cancel')}}</el-button>
          <el-button type="primary" @click="addTrustedDomain">{{$t('care.messenger.sure')}}</el-button>
        </div>
      </el-dialog>
      <el-dialog
          :title="addSubGroupRow.id?$t('care.sub_group.edit'):$t('care.sub_group.add')"
          :visible.sync="addSubGroupVisible"
          width="500px"
          append-to-body>
        <el-form :model="addSubGroupRow" :label-width="formLabelWidth" style="max-width: 400px">
          <el-form-item :label="$t('care.sub_group.name')" prop="name" required>
            <div class="formval">
              <el-input  v-model="addSubGroupRow.name"></el-input>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addSubGroupVisible = false">{{$t('care.messenger.cancel')}}</el-button>
          <el-button type="primary" @click="submitSubGroupRow">{{$t('care.messenger.sure')}}</el-button>
        </div>
      </el-dialog>

      <el-dialog
          title="Webhook"
          :visible.sync="webhookSubGroupVisible"
          width="900px"
          append-to-body>
        <el-form :model="subwebhook" :label-width="formLabelWidth" style="max-width: 860px">
          <el-form-item :label="$t('care.webhook.url')" prop="url" required>
            <div class="formval">
              <el-input  v-model="subwebhook.url"></el-input>
            </div>
          </el-form-item>

          <el-form-item :label="$t('care.webhook.token')" prop="token" required>
            <div class="formval">
              <el-input  v-model="subwebhook.token"></el-input>
            </div>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="webhookSubGroupVisible = false">{{$t('care.messenger.cancel')}}</el-button>
          <el-button type="primary" @click="submitWebhookSubGroup">{{$t('care.messenger.sure')}}</el-button>
        </div>
      </el-dialog>

      <el-drawer
          :title="$t('care.trusted_domain.setting')"
          :visible.sync="manageTrustedDomainsVisible"
          append-to-body
          size="80%"
      >
        <div style="height:100%;padding: 30px">
          <div class="list" style="height:100%;">
            <div class="header">
              <el-button size="mini"  plain type="primary" icon="el-icon-refresh"  @click="refreshTrustedDomain()"></el-button>
              <el-button size="mini"  plain type="primary" icon="el-icon-plus"  @click="handleAddTrustedDomain()">{{$t("care.add")}}</el-button>
            </div>
            <div class="content" style="height:calc( 100% - 40px);">
              <el-empty v-if="hadGetTrustedDomains&&tableData.length===0"></el-empty>
              <el-table
                  v-else
                  class="table"
                  v-loading="spinning"
                  :data="tableData"
                  height="calc( 100vh - 190px)"
                  highlight-current-row
              >
                <el-table-column
                    prop="domain"
                    :label="$t('care.trusted_domain.domain')"
                ></el-table-column>

                <el-table-column
                    prop="name"
                    :label="$t('care.trusted_domain.name')"
                ></el-table-column>
                <el-table-column
                    prop="enable"
                    :label="$t('care.trusted_domain.enable')"
                >
                  <template slot-scope="scope">
                    <!--                  <a-tag v-if="scope.row.enable===1" color="blue">{{$t('care.trusted_domain.enable_1')}}</a-tag>-->
                    <!--                  <a-tag v-else >{{$t('care.trusted_domain.enable_0')}}</a-tag>-->
                    <el-switch
                        @change="onDomainEnable(scope.row)"
                        :active-value="1"
                        :inactive-value="0"
                        v-model="scope.row.enable"
                        active-color="#13ce66"
                        inactive-color="#ff4949">
                    </el-switch>
                  </template>

                </el-table-column>

                <!--              <el-table-column-->
                <!--                  prop="created_at"-->
                <!--                  :label="$t('care.created_at')"-->
                <!--              ></el-table-column>-->
                <el-table-column
                    :label="$t('care.ops')"
                >
                  <template slot-scope="scope">
                    <div class="options">
                      <el-button
                          size="mini"
                          type="danger" plain
                          icon="el-icon-delete"
                          @click="handleDeleteTrustedDomain(scope.row)"></el-button>
                      <el-button
                          :title="$t('care.edit')"
                          size="mini"
                          type="primary" plain
                          icon="el-icon-edit"
                          @click="handleEditTrustedDomain(scope.row)"></el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                  small
                  layout="prev, pager, next"
                  :current-page.sync="page"
                  :page-size="pageSize"
                  @current-change="pageChange"
                  :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="manageTrustedDomainsVisible=false">{{$t('care.messenger.close')}}</el-button>
        </div>
      </el-drawer>
      <el-drawer
          :title="$t('care.sub_group.setting')"
          :visible.sync="manageSubGroupVisible"
          append-to-body
          size="80%"
      >
        <div style="height:100%;padding: 30px">
          <div class="list" style="height:100%;">
            <div class="header">
              <el-button size="mini"  plain type="primary" icon="el-icon-refresh"  @click="refreshSubGroups()"></el-button>
              <el-button size="mini"  plain type="primary" icon="el-icon-plus"  @click="handleAddSubGroup()">{{$t("care.add")}}</el-button>
              <el-tooltip style="margin-left: 15px;color: #409EFF;" effect="dark" :content="$t('care.sub_group.tips')" placement="top">
                <i class="el-icon-warning-outline"></i>
              </el-tooltip>
            </div>
            <div class="content" style="height:calc( 100% - 40px);">
              <el-empty v-if="hadGetSubGroups&&tableDataSubGroup.length===0"></el-empty>
              <el-table
                  v-else
                  class="table"
                  v-loading="spinningSubGroup"
                  :data="tableDataSubGroup"
                  height="calc( 100vh - 190px)"
                  highlight-current-row
              >
                <el-table-column
                    prop="name"
                    :label="$t('care.sub_group.name')"
                    width="250"
                ></el-table-column>
                <el-table-column
                    prop="enable"
                    :label="$t('care.sub_group.enable')"
                    width="100"
                >
                  <template slot-scope="scope">
                    <!--                  <a-tag v-if="scope.row.enable===1" color="blue">{{$t('care.trusted_domain.enable_1')}}</a-tag>-->
                    <!--                  <a-tag v-else >{{$t('care.trusted_domain.enable_0')}}</a-tag>-->
                    <el-switch
                        @change="onSubGroupEnable(scope.row)"
                        :active-value="1"
                        :inactive-value="0"
                        v-model="scope.row.enable"
                        active-color="#13ce66"
                        inactive-color="#ff4949">
                    </el-switch>
                  </template>

                </el-table-column>

                <!--              <el-table-column-->
                <!--                  prop="created_at"-->
                <!--                  :label="$t('care.created_at')"-->
                <!--              ></el-table-column>-->
                <el-table-column
                    :label="$t('care.ops')"
                >
                  <template slot-scope="scope">
                    <div class="options">

                      <el-button
                          :disabled="!scope.row.enable"
                          :title="$t('care.edit')"
                          size="mini"
                          type="primary" plain
                          icon="el-icon-edit"
                          @click="handleEditSubGroup(scope.row)"></el-button>
                      <el-button
                          size="mini"
                          type="primary" plain
                          icon="el-icon-setting"
                          @click="handWebhookSubGroup(scope.row)">Webhook</el-button>
                      <el-button
                          :disabled="!scope.row.enable"
                          :title="$t('care.messenger.messenger')"
                          size="mini"
                          type="primary" plain
                          @click="handleEditSubGroupMessengers(scope.row)">{{$t('care.sub_group.messenger_setting')}}</el-button>
                      <el-button
                          :disabled="!scope.row.enable"
                          :title="$t('care.virtual_care')"
                          size="mini"
                          type="primary" plain
                          @click="handleEditSubGroupCares(scope.row)">{{$t('care.sub_group.care_setting')}}</el-button>
                      <el-dropdown :disabled="!scope.row.enable" style="margin-left: 15px; cursor: pointer;color: #409EFF;" size="mini" @command="(cmd)=>handleCommand(cmd,scope.row)">
                        <el-button  size="mini">
                          {{$t('care.settingscript.copy')}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item command="zh_TW">繁體中文</el-dropdown-item>
                          <el-dropdown-item command="en">English</el-dropdown-item>
                          <el-dropdown-item command="zh_CN">简体中文</el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                  small
                  layout="prev, pager, next"
                  :current-page.sync="pageSubGroup"
                  :page-size="pageSizeSubGroup"
                  @current-change="pageChangeSubGroup"
                  :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="manageTrustedDomainsVisible=false">{{$t('care.messenger.close')}}</el-button>
        </div>
      </el-drawer>

      <el-dialog
          :title="$t('care.tag_event.setting')"
          :visible.sync="manageTagEventVisible"
          width="1000px"
          append-to-body>
        <el-tabs tab-position="left" style="height: 200px;">
          <el-tab-pane :label="$t('care.tag_event.analytics_id')">
            <div class="formval">
              <el-input size="small" placeholder="填写你的实例ID"  v-model="tagEventConf.analytics_id"></el-input>
              <el-button size="small"  @click="onSaveTagEvent" type="primary">{{$t('care.save')}} <i class="el-icon-right"></i></el-button>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('care.tag_event.provider_setting')">
            <div v-for="providerConf in eventProviderConfs" :key="providerConf.id">
              <div class="formval">
                <span style="width:400px;text-align: right;padding-right: 20px">{{providerConf.messenger.provider+'|'+providerConf.messenger.name+'|'+providerConf.conf_arr.label}}</span>
                <el-input size="small" style="width: 200px " :disabled="!providerConf.conf_arr.editable" v-model="providerConf.conf_arr.unique_id"></el-input>
                <el-button size="small"  @click="onsaveEventProviderConf(providerConf)" v-if="providerConf.conf_arr.editable" type="primary">{{$t('care.save')}} <i class="el-icon-right"></i></el-button>
              </div>
            </div>
          </el-tab-pane>

        </el-tabs>
<!--        <el-form :model="tagEventConf" :label-width="formLabelWidth" style="max-width: 900px">-->
<!--          <el-form-item :label="$t('care.tag_event.analytics_id')" prop="analytics_id" required>-->
<!--            <div class="formval">-->
<!--              <el-input v-model="tagEventConf.analytics_id"></el-input>-->
<!--              <el-button @click="onSaveTagEvent" type="primary">{{$t('care.save')}} <i class="el-icon-right"></i></el-button>-->
<!--            </div>-->
<!--          </el-form-item>-->
<!--        </el-form>-->
<!--        <el-divider content-position="center">{{$t('care.tag_event.provider_setting')}}</el-divider>-->
<!--        <el-form :model="tagProviderConf" label-width="400px" style="max-width: 800px">-->
<!--          <el-form-item v-for="providerConf in eventProviderConfs" :key="providerConf.id" :label="providerConf.messenger.provider+'|'+providerConf.messenger.name+'|'+providerConf.conf_arr.label">-->
<!--            <div class="formval">-->
<!--              <el-input :disabled="!providerConf.conf_arr.editable" v-model="providerConf.conf_arr.unique_id"></el-input>-->
<!--              <el-button @click="onsaveEventProviderConf(providerConf)" v-if="providerConf.conf_arr.editable" type="primary">{{$t('care.save')}} <i class="el-icon-right"></i></el-button>-->
<!--            </div>-->
<!--          </el-form-item>-->
<!--        </el-form>-->
        <div slot="footer" class="dialog-footer">
          <el-button @click="manageTagEventVisible = false">{{$t('care.messenger.close')}}</el-button>
        </div>
      </el-dialog>

      <el-dialog
          :title="$t('care.sub_group.care_setting')"
          :visible.sync="editSubGroupCareVisible"
          append-to-body>
        <div style="display: flex;flex-direction: row;width: 100%;justify-content: center;">

          <el-transfer
              filterable
              filter-placeholder="search"
              :filter-method="filterCares"
              v-model="editSubGroup.care_arr"
              :titles="['全部', '接待员']"
              :props="{
                key: 'id',
                label: 'name'
              }"
              :data="all_cares">
          </el-transfer>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editSubGroupCareVisible = false">{{$t('care.messenger.close')}}</el-button>
          <el-button @click="onSaveSubGroupCare" type="primary">{{$t('care.save')}}</el-button>
        </div>
      </el-dialog>

      <el-dialog
          :title="$t('care.sub_group.messenger_setting')"
          :visible.sync="editSubGroupMessengerVisible"
          append-to-body>
        <div style="display: flex;flex-direction: row;width: 100%;justify-content: center;">

          <el-transfer

              v-model="editSubGroup.messenger_arr"
              :titles="['全部', '信使']"
              :props="{
                key: 'id',
                label: 'name'
              }"
              :data="all_messengers">
          </el-transfer>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editSubGroupMessengerVisible = false">{{$t('care.messenger.close')}}</el-button>
          <el-button @click="onSaveSubGroupMessenger" type="primary">{{$t('care.save')}}</el-button>
        </div>
      </el-dialog>

      <el-tabs type="border-card">
        <el-tab-pane label="基础">
          <div style="min-height: calc(100vh - 300px)">
            <el-form class="setting" v-if="editRow" :model="editRow" :label-width="formLabelWidth">
              <el-form-item>
                <span slot="label">
                  <el-tooltip style="margin-right: 2px;color: #409EFF;" effect="dark" :content="$t('care.fieldtip.timeout')" placement="top">
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>{{$t('care.timeout')}}
                </span>
                <div class="formval">
                  <el-input-number  size="small" @change="changeLimit(editRow)" :min="10" v-model="editRow.time_limit" :step="10"></el-input-number>
                </div>
              </el-form-item>
              <el-form-item >
                <span slot="label">
                  <el-tooltip style="margin-right: 2px;color: #409EFF;" effect="dark" :content="$t('care.fieldtip.mail')" placement="top">
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>{{$t('care.mail')}}
                </span>
                <div class="formval">
                  <el-tag
                      size="small"
                      style="margin-right: 10px"
                      :key="email"
                      v-for="email in editRow.emails"
                      closable
                      :disable-transitions="false"
                      @close="handleCloseEmail(email)">
                    {{email}}
                  </el-tag>
                  <el-input
                      size="small"
                      placeholder="email"
                      style="width: 300px"
                      class="input-new-email"
                      v-if="addMailInputVisible"
                      v-model="addMailInputValue"
                  >
                    <el-button  size="small" @click="handleInputConfirm" slot="append" icon="el-icon-plus"></el-button>
                  </el-input>
                  <el-button  size="small" style="width: 180px" v-else class="button-new-tag"  @click="showInput">{{$t('care.messenger.addmail')}}</el-button>

                </div>
              </el-form-item>

              <el-form-item :label="$t('care.messenger.messenger')" >
                <div class="formval" style="display: flex;justify-content: flex-start;align-items: center;flex-wrap: wrap;">
                  <el-tag
                      style="cursor: pointer;margin: 5px;"
                      :key="index"
                      v-for="(messenger,index) in editRow.messengers"
                      :disable-transitions="false"
                      :type="messenger.enable?'':'warning'"
                      :closable="messenger.name!=='topkee'&&messenger.default!==1"
                      @close="handleDeleteMessenger(messenger)"
                      @click="onEditMessenger(messenger)"
                  >
                    <strong>{{messenger.provider==='topkee'?'website':messenger.provider}}</strong> | {{messenger.provider==='topkee'?'website':messenger.name}}
                    <span v-if="messenger.sendid">({{messenger.sendid}})</span>
                  </el-tag>
                  <el-button size="small" style="width: 150px;margin: 5px"  class="button-new-tag  el-icon-plus"  @click="onAddMessenger">{{ $t('care.messenger.addmessenger') }}</el-button>
                </div>
              </el-form-item>
              <el-form-item>
                <span slot="label">
                  <el-tooltip style="margin-right: 2px;color: #409EFF;" effect="dark" :content="$t('care.fieldtip.colors')" placement="top">
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>{{$t('care.messenger.colors')}}
                </span>
                <div class="formval">
                  <div @click="toggleColorPicker(null)" :style="{height: '30px',width: '30px',cursor:'pointer',position:'relative',backgroundColor:getColor(editRow)}">
                    <div
                        @click.stop
                        v-show="showColorPicker"
                        style="position: absolute; top: calc(100% + 5px);left: 0;background-color: #FFFFFF;z-index: 2;"
                    >
                      <sketch-picker
                          v-model="editRow.colors"
                          :preset-colors="[
                                    '#0A7CFF', '#44BEC7', '#ffc300',
                                    '#d696bb', '#6699CC', '#13CF13',
                                    '#FF7E29', '#E68585', '#7646FF',
                                    '#20CEF5', '#fa3c4c', '#D4A88C',
                                    '#FF5CA1', '#67B868', '#A695C7',
                                 ]"
                      ></sketch-picker>
                    </div>
                  </div>

                </div>
              </el-form-item>

              <el-form-item :label="$t('care.settingscript.setting')" >
                <div class="formval">
                  <el-button  size="small" style="width: 150px" plain type="primary" class="button-new-tag"  @click="onOpenEmbedOption">{{$t('care.settingscript.setting')}}</el-button>

                  <el-dropdown  size="small" style="cursor: pointer;color: #409EFF;margin-left: 30px" @command="(cmd)=>handleCommand(cmd,null)">
                    <el-button  size="small" >
                      {{$t('care.settingscript.copy')}}<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="zh_TW">繁體中文</el-dropdown-item>
                      <el-dropdown-item command="en">English</el-dropdown-item>
                      <el-dropdown-item command="zh_CN">简体中文</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </el-form-item>
              <el-form-item>
                <span slot="label">
                  <el-tooltip style="margin-right: 2px;color: #409EFF;" effect="dark" :content="$t('care.trusted_domain.tips')" placement="top">
                    <i class="el-icon-warning-outline"></i>
                  </el-tooltip>{{$t('care.trusted_domain.setting')}}
                </span>
                <div class="formval">
                  <el-button  size="small" plain type="primary" class="button-new-tag"  @click="onManageTrustedDomainsVisible">{{$t('care.trusted_domain.setting')}}</el-button>
                </div>

              </el-form-item>

            </el-form>

          </div>
        </el-tab-pane>
        <el-tab-pane label="Webhook">
          <div style="min-height: calc(100vh - 300px)">
            <el-form class="setting" v-if="editRow" :model="editRow" :label-width="formLabelWidth">
              <el-alert
                  v-if="webhook.verify!==1"
                  show-icon
                  style="margin-bottom: 20px;"
                  :title="$t('care.webhook.tip_title')"
                  :description="$t('care.webhook.tip_description')"
                  type="success">
              </el-alert>
              <el-form-item :label="$t('care.webhook.url')" >
                <div class="formval">
                  <el-input
                      size="small"
                      placeholder="url"
                      v-model="webhook.url"
                      :disabled="webhook.verify===1"
                  >
                  </el-input>
                </div>
              </el-form-item>
              <el-form-item :label="$t('care.webhook.token')" >
                <div class="formval">
                  <el-input
                      size="small"
                      show-password
                      style="width: 200px"
                      placeholder="token"
                      :disabled="webhook.verify===1"
                      v-model="webhook.token">
                  </el-input>
                  <span class="switch" >{{$t('care.webhook.verify')}}</span>
                  <el-switch
                      size="small"
                      disabled
                      :active-value=1
                      :inactive-value=0
                      v-model="webhook.verify">
                  </el-switch>
                  <span class="switch">{{$t('care.webhook.state')}}</span>
                  <el-switch
                      size="small"
                      disabled
                      :active-value=1
                      :inactive-value=0
                      v-model="webhook.state">
                  </el-switch>
                </div>
              </el-form-item>
              <div>
                <div style="text-align: left;">
                  <div style="min-width: 120px;display: inline-block;text-align: right;padding-right: 15px;" class="switch">啟用自訂回傳</div>
                  <el-switch
                      size="small"
                      :active-value=1
                      :inactive-value=0
                      :disabled="webhook.verify===1"
                      v-model="webhook.enable_custom_fields">
                  </el-switch>
                </div>
                <el-form-item prop="formItems">
                   <span slot="label">
                      <el-tooltip style="margin-right: 2px;color: #409EFF;" effect="dark" :content="$t('care.fieldtip.tips')" placement="top">
                        <i class="el-icon-warning-outline"></i>
                      </el-tooltip>{{ $t('care.webhook_fiedls') }}
                  </span>
                  <div class="formval">
                    <div>
                      <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;margin-bottom: 10px" v-for="formitem in webhook.form_items" :key="formitem.source">
                        <span style="min-width: 5em">{{formitem.label}}</span>
                        <el-input  size="small" :disabled="webhook.verify===1" v-model="formitem.field"></el-input>
                      </div>
                    </div>

                  </div>
                </el-form-item>
              </div>

              <el-form-item>
                <div>
                  <el-button  size="small" v-if="webhook.verify" class="switch"  @click="onDeleteWebhook"  type="danger" icon="el-icon-delete"  >{{$t('care.webhook.del')}}</el-button>
                  <el-button   size="small" type="primary" v-if="!webhook.verify" @click="onSetWebhook" class="switch" >{{$t('care.webhook.ok')}}</el-button>

                </div>
              </el-form-item>

            </el-form>
          </div>

        </el-tab-pane>
        <el-tab-pane :label="$t('care.userform.title')">
          <div style="min-height: calc(100vh - 300px)">
            <el-form :model="userform"   :label-width="formLabelWidth" style="max-width: 800px">
              <el-alert
                  show-icon
                  style="margin-bottom: 20px;"
                  :title="$t('care.userform.tip_title')"
                  :description="$t('care.userform.tip_description')"
                  type="success">
              </el-alert>
              <el-form-item :label="$t('care.userform.fieldnamage')" prop="formItems">
                <div class="formval">
                  <div>
                    <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;margin-bottom: 10px" v-for="(formitem,index) in userform.formItems" :key="index">
                      <i @click="deleteFormItem(index)" style="font-size: larger;color: #f66161; cursor: pointer;margin-right: 5px;" class="el-icon-remove-outline"></i>
                      <el-input  size="small"  v-model="formitem.field"></el-input>
                      <el-input  size="small" v-model="formitem.label"></el-input>
                      <el-input size="small" disabled  v-model="formitem.val_type"></el-input>
                    </div>
                    <div style="text-align: left;">
                      <el-button  size="small" style="width: 180px;margin-left: 22px;" class="button-new-tag el-icon-plus"  @click="addFormItem">{{$t('care.userform.add')}}</el-button>

                    </div>

                  </div>

                </div>
              </el-form-item>
              <el-form-item>
                <el-button  size="small" :loading="formItemLoading" type="primary" @click="saveFormItems">{{$t('care.save')}}</el-button>
              </el-form-item>
            </el-form>
          </div>


        </el-tab-pane>
        <el-tab-pane label="更多设置">
         <div style="min-height: calc(100vh - 300px);text-align: left;padding: 40px">

           <el-button :loading="loadingTagEvent" size="small" plain type="primary" class="button-new-tag"  @click="onManageTagEventVisible">{{$t('care.tag_event.setting')}}</el-button>

           <el-button  size="small" plain type="primary" class="button-new-tag"  @click="onManageSubGroupVisible">{{$t('care.sub_group.setting')}}</el-button>

         </div>
        </el-tab-pane>
      </el-tabs>

    </div>
  </el-drawer>
</template>

<script>
import {whatsappPhones} from "../../../libs/apis/whatsapp";
import {addBusiness, getBusinessList} from "../../../libs/apis/business";
import { Sketch } from 'vue-color'
import {remove} from "lodash/array";
import {deleteMessenger, postAddMessenger, putMessengerEnable} from "../../../libs/apis/messenger";
import {addmail, delmail, getCare} from "../../../libs/apis/care";
import {message} from "ant-design-vue";
import {addGroup, deleteWebhook, setWebhook, updateGroup} from "../../../libs/apis/group";
import {get_fbcomment_auths_api, getAuthToken, getTokens} from "@/libs/apis/authing";
import linkMixin from "@/mixins/linkMixin";
import {trim} from "@/libs/utils/utils";
import {deleteUserGreetings, getUserGreetings, saveUserGreetings} from "@/libs/apis/usergreeting";
import {getFormItems, postFormItems} from "@/libs/apis/form";
import {fb_accounts} from "@/libs/apis/facebookapi";
import {getWabaChannels} from "@/libs/apis/waba";
import {getServiceAdmin, getServices, subscribeService} from "@/libs/apis/service";
import {getFacebookAuthPages, getFacebookAuthPageToken} from "@/libs/apis/facebookauth";

import {
  addTrustedDomain,
  deleteTrustedDomain,
  enableTrustedDomain,
  getTrustedDomains,
  updateTrustedDomain
} from "@/libs/apis/trustedDomain";
import {getEventConf, getEventProviderConfs, postEventConf, postEventProviderConf} from "@/libs/apis/tagEvent";
import {
  addSubGroup,
  deleteSubGroup,
  enableSubGroup,
  getSubGroups,
  postSubGroupCares, postSubGroupMessengers, postSubGroupWebhook,
  updateSubGroup
} from "@/libs/apis/subGroups";
import {getGroupCares} from "@/libs/apis/groupcare";

const defaultEmbedOption={
  position:{
    bottom:40,
    bottom_mobile:20,
    right:20,
    left:20,
    frame_width:350,
    btn_width:60,
    btn_size:'normal',
    btn_size_mobile:'normal',
    frame_height:70,
    side:'left',
    show_qrcode:1,
  },
  titleList:[
    'Hello, do you need help?',
    'Hi, welcome to contact me if you have any questions?',
    'Welcome to Chat with us.'
  ],
};
const defHook={
  url:null,
  token:null,
  verify:0,
  state:0,
  enable_custom_fields: 0,
  form_items: [
    {
      "field": "name",
      "source": "name",
      "label": "名稱",
    },
    {
      "field": "phone",
      "source": "phone",
      "label": "手機號",
    },{
      "field": "from_source",
      "source": "from_source",
      "label": "來源",
    },{
      "field": "messenger_provider",
      "source": "messenger_provider",
      "label": "頻道",
    },{
      "field": "tto_clid",
      "source": "tto_clid",
      "label": "tto_clid",
    },
  ],
};
export default {
  name: "GroupSetting",
  props: [
    "editRow",
    "editVisible",
  ],
  data(){
    const args=this.getUrlArgObject();
    const account_id=args.account_id;
    return {
      account_id:account_id,
      webhook:Object.assign({},defHook),
      centervisible:false,
      addMailInputVisible:false,
      addMessengerVisible:false,
      settingScriptVisible:false,
      add_messenger_loading:false,
      addMailInputValue:null,
      addGreetingValue:null,
      addGreetingVisible:false,
      addMessengerRow:{
        provider:'whatsapp:waba-channel-service',
        business_id:'',
        name:'',
        auth_id:'',
        token:'',
        phone_number_id:null,
        group_id: null,
        page_id: null,
        channel_id: null,
        auth_provider: null,
        enable:1,
        private_checked:true,
      },
      addBizRow:{
        business_name:null,
        business_id:null,
        provider:'whatsapp',
      },
      formLabelWidth:'120px',
      formLabelWidth2:'180px',

      addBizVisible:false,
      manageTrustedDomainsVisible:false,
      manageTagEventVisible:false,
      manageSubGroupVisible:false,
      eventProviderConfs:[],
      authWabaVisible:false,
      showColorPicker:false,
      addgreetingLoading:false,
      formItemVisible:false,
      formItemLoading:false,
      provider_whatsapp_visiable:false, // 隐藏旧版whatsapp，参数问题
      userform:{
        formItems:[
          {
            "field": "name",
            "label": "名稱",
            "val_type": "string"
          },
          {
            "field": "desc",
            "label": "描述",
            "val_type": "string"
          },
          {
            "field": "tel",
            "label": "電話",
            "val_type": "string"
          },
          {
            "field": "email",
            "label": "郵箱",
            "val_type": "string"
          },
          {
            "field": "job",
            "label": "職稱",
            "val_type": "string"
          },
          {
            "field": "company",
            "label": "公司",
            "val_type": "string"
          }
        ],
      },
      bizs:[],
      tokens:[],
      fb_comment_auths:[],
      whatsappPhones:[],
      rules_add_messenger: {
        business_id: [
          { required: true, message: this.$t('care.messenger.business'), trigger: 'blur' }
        ],
        auth_id: [
          { required: true, message: this.$t('care.messenger.auth'), trigger: 'blur' }
        ],
        name: [
          { required: true, message: this.$t('care.messenger.name'), trigger: 'blur' }
        ],
        phone_number_id: [
          { required: true, message: this.$t('care.messenger.phone'), trigger: 'blur' }
        ],
        page_id: [
          { required: true, message: this.$t('care.messenger.page_id'), trigger: 'blur' }
        ],
        channel_id: [
          { required: true, message: this.$t('care.messenger.channel'), trigger: 'blur' }
        ],

      },
      rules_add_biz: {
        business_id: [
          { required: true, message: this.$t('care.messenger.business_id'), trigger: 'blur' }
        ],
        business_name: [
          { required: true, message: this.$t('care.messenger.business_name'), trigger: 'blur' }
        ],
      },
      embedOption:defaultEmbedOption,
      rules_settingscript:{
        // bottom: [
        //   { required: true, message: this.$t('care.settingscript.bottom'), trigger: 'blur' }
        // ],
        // bottom_mobile: [
        //   { required: true, message: this.$t('care.settingscript.bottom_mobile'), trigger: 'blur' }
        // ],
        // right: [
        //   { required: true, message: this.$t('care.settingscript.right'), trigger: 'blur' }
        // ],
        // left: [
        //   { required: true, message: this.$t('care.settingscript.left'), trigger: 'blur' }
        // ],
        // frame_width: [
        //   { required: true, message: this.$t('care.settingscript.frame_width'), trigger: 'blur' }
        // ],
        // btn_width: [
        //   { required: true, message: this.$t('care.settingscript.btn_width'), trigger: 'blur' }
        // ],
        // frame_height: [
        //   { required: true, message: this.$t('care.settingscript.frame_height'), trigger: 'blur' }
        // ],
      },
      throttleCall:()=>{},
      inited:false,
      greetings:[],
      script_lang:'zh_TW',
      script_sub_id:'',
      fbpages:[],
      edit_messenger_id:null,
      edit_messenger:null,
      auth_waba_src:null,
      auth_facebook_auth_src:null,
      third_auth_src:null,
      facebookAuthVisible:false,
      thirdAuthVisible:false,

      wabachannels:[],
      fbcomment_channels:[],

      tableHeight:500,
      tableData:[],
      hadGetTrustedDomains:false,
      loadingTagEvent:false,
      hadGetSubGroups:false,
      spinning:false,
      addDomainVisible:false,
      addDomainRow:{
        id:null,
        name:null,
        domain:null,
      },
      page:1,
      total:0,
      pageSize:10,

      spinningSubGroup:false,
      hadGetSubGroup:false,
      pageSubGroup:1,
      totalSubGroup:0,
      pageSizeSubGroup:10,
      addSubGroupVisible:false,
      webhookSubGroupVisible:false,
      editSubGroupCareVisible:false,

      tableDataSubGroup:[],
      addSubGroupRow:{
        name:null,
      },

      tagEventConf:{
        analytics_id:null,
      },
      tagProviderConf:{},

      all_messengers:[],
      all_cares:[],
      editSubGroup:{
        cares:[],
        messengers:[],
      },
      subwebhook:{
        url:null,
        token:null,
      },
      editSubGroupMessengerVisible:false,
    };
  },
  components: {
    'sketch-picker': Sketch,
  },
  emits: ["onClose","onChangeEditRow"],
  computed: {
    whatsappphoneListener(){
      return this.addMessengerRow.business_id+'--'+this.addMessengerRow.token
    },
  },
  watch:{
    editRow(newVal){
      console.log('------------editRow------------------')
      this.addMessengerRow.group_id=newVal.id;
      this.initWebHook(newVal);
    },
    editVisible(newVal){
      this.centervisible=newVal;
    },
    async whatsappphoneListener(newVal,oldVal) {
      if(this.addMessengerRow.business_id!==''&&this.addMessengerRow.token!=='') {
        const rs = await whatsappPhones(this.addMessengerRow.business_id,this.addMessengerRow.token);

        this.whatsappPhones=rs.list.data;
      }
    },
    async 'addMessengerRow.auth_id'(newVal,oldVal){
      const find= this.tokens.find(item=>item.id===this.addMessengerRow.auth_id);
      if(find){
        this.addMessengerRow.token=find.access_token
      }
      if(this.addMessengerRow.provider==='messenger'){
        this.getFbPages(this.addMessengerRow.auth_id);
      }
      if(this.addMessengerRow.provider==='fbcomment'){
        this.getFbPages(this.addMessengerRow.auth_id,'fbcomment_channels');
        const rs = await getAuthToken(this.addMessengerRow.auth_id);
        this.addMessengerRow.token=rs.access_token
      }
    },
    'addMessengerRow.page_id'(){
      if(this.addMessengerRow.provider==='messenger'&&!this.addMessengerRow.name){
        this.addMessengerRow.name=this.fbpages.find(item=>item.id==this.addMessengerRow.page_id)?.name
      }
    },
    async 'addMessengerRow.provider'(){
      this.onProvider();
    },
    'addMessengerRow.channel_id'(){
      if(this.addMessengerRow.provider==='whatsapp:waba-channel-service'&&!this.addMessengerRow.name){
        this.addMessengerRow.name=this.wabachannels.find(item=>item.id===this.addMessengerRow.channel_id)?.display_name.split('+')[0];
      }else if(this.addMessengerRow.provider==='fbcomment'&&!this.addMessengerRow.name){
        this.addMessengerRow.name=this.fbcomment_channels.find(item=>item.id===this.addMessengerRow.channel_id)?.name;
        this.addMessengerRow.page_id=this.fbcomment_channels.find(item=>item.id===this.addMessengerRow.channel_id)?.id;

      }
    },
    async 'addBizRow.provider'(newVal,oldVal){
      this.bizs=await getBusinessList(newVal);
    },
    'editRow.colors'(newVal,oldVal){
      if(!oldVal||!this.inited) return;
      this.throttleCall();
    },
    'embedOption':{
      handler(newVal,oldVal){
        if(!oldVal||!this.inited) return;
        this.saveEmbedOption()
      },
      deep:true
    }
  },
   mounted() {
      this.setEmbedOption();
      let that=this;
      // getTokens().then((tokens)=>{
      //   that.tokens=tokens;
      // });
      this.initWebHook(this.editRow);

      this.throttleCall=this.throttle(function (){
        that.updateColor()
      },2000,3000);
      this.centervisible=true;
      this.inited=true;
      this.apiGetUserGreetings();
      this.getFromItemsData();
      this.addMessengerRow.auth_provider = null;
      this.onProvider();
      window.addEventListener('message',function (event) {
        // console.log(event.data.action)
        if(event.data.action=='close'){
          that.authWabaVisible=false;
        }else if(event.data.action=='authed'){
          that.authWabaVisible=false;
          that.add_messenger_loading=true;
          setTimeout(async function () {
            await that.onProvider();
            that.add_messenger_loading=false;
          },1000*30);
        }
      })

  },
  mixins:[linkMixin],
  methods:{
    async onsaveEventProviderConf(providerConf){
      if(!providerConf.conf_arr.unique_id){
        this.$message.warning('请填写相关信息');
        return;
      }
      await postEventProviderConf(providerConf.messenger.id,{conf_arr:providerConf.conf_arr});

      this.$message.success('success');
    },
    async onSaveTagEvent(){
      if(!this.tagEventConf.analytics_id){

        this.$message.warning('请填写Tag实例ID');
        return;
      }
      await postEventConf(this.editRow.id,this.tagEventConf);
      this.$message.success('success');
      // this.manageTagEventVisible=false;
    },
    async onDomainEnable(row){
      await enableTrustedDomain(this.editRow.id,row.id,row.enable);
      this.$message.success('success')
    },

    async onManageTagEventVisible(){
      this.loadingTagEvent=true;
      const conf=await getEventConf(this.editRow.id);
      if(conf)
      {
        this.tagEventConf.analytics_id=conf.analytics_id;
      }
     this.eventProviderConfs= await getEventProviderConfs(this.editRow.id);
      this.manageTagEventVisible=true;
      this.loadingTagEvent=false;
    },
    onManageTrustedDomainsVisible(){

      this.manageTrustedDomainsVisible=true;
      this.refreshTrustedDomain();
    },
    onManageSubGroupVisible(){
      this.manageSubGroupVisible=true;
      this.refreshSubGroups();
    },
    filterCares(query, item){
      return item.name.indexOf(query) > -1;
    },
    async onSaveSubGroupCare(){
      await postSubGroupCares(this.editRow.id,this.editSubGroup.id,{care_arr:this.editSubGroup.care_arr});
      this.editSubGroupCareVisible=false;
      this.refreshSubGroups();
      this.$message.success('success')
    },

    async onSaveSubGroupMessenger(){
      await postSubGroupMessengers(this.editRow.id,this.editSubGroup.id,{messenger_arr:this.editSubGroup.messenger_arr});
      this.editSubGroupMessengerVisible=false;
      this.refreshSubGroups();
      this.$message.success('success')
    },
    pageChange(page){
      this.page=page;
      this.getTrustedDomainList();
    },

    async getTrustedDomainList(){
      this.spinning=true;
      const map=new Map();
      // map.set('users.name',this.search.name);
      // map.set('receive_able',this.search.receive_able);
      const {data,total}=await getTrustedDomains(this.editRow.id,{
        filter:map,
        page:{
          number:this.page,
          size:this.pageSize,
        }
      });
      this.tableData=data;
      this.total= total;
      this.spinning=false;
      this.hadGetTrustedDomains=true;

    },
    async getSubGroupList(){
      this.spinningSubGroup=true;
      const map=new Map();
      // map.set('users.name',this.search.name);
      // map.set('receive_able',this.search.receive_able);
      const {data,total}=await getSubGroups(this.editRow.id,{
        filter:map,
        page:{
          number:this.pageSubGroup,
          size:this.pageSizeSubGroup,
        },
        include:['cares','messengers','webhook'],
      });
      this.tableDataSubGroup=data;
      this.totalSubGroup= total;
      this.spinningSubGroup=false;
      this.hadGetSubGroup=true;

    },

    pageChangeSubGroup(page){
      this.page=page;
      this.getSubGroupList();
    },
    async submitSubGroupRow(){
      if(!this.addSubGroupRow.name){
        this.$message.warning('请输入名称')
        return;
      }
      if(this.addSubGroupRow.id){
        await updateSubGroup(this.editRow.id,this.addSubGroupRow.id,this.addSubGroupRow)
      }else{
        await addSubGroup(this.editRow.id,this.addSubGroupRow);

      }

      this.addSubGroupVisible=false;
      this.refreshSubGroups();
    },
    async handleDeleteSubGroup(row){
      let that=this;
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await deleteSubGroup(that.editRow.id,row.id)
        that.refreshSubGroups();
      });
    },
    async onSubGroupEnable(row){
      await enableSubGroup(this.editRow.id,row.id,row.enable);
      this.$message.success('success')
    },
    async handleEditSubGroup(row){
      this.addSubGroupRow={
        id:row.id,
        name:row.name,
      }
      this.addSubGroupVisible=true;
    },
    async submitWebhookSubGroup(){

      if(this.subwebhook.url!==null||this.subwebhook.token!==null){
        try {
          await postSubGroupWebhook(this.editRow.id,this.subwebhook.id, this.subwebhook);
          this.$message.success('success')
          this.webhookSubGroupVisible=false;
          this.refreshSubGroups();

        }catch (e) {
          console.log(e)
          this.$message.error(e.data?.message||'error')
        }

      }else{
        console.log( this.subwebhook)
      }
    },
    async handWebhookSubGroup(row){
      this.subwebhook={
        id:row.id,
        url:row.webhook?.url,
        token:row.webhook?.token,
      }

      this.webhookSubGroupVisible=true;
    },

    async handleEditSubGroupMessengers(row){
      this.editSubGroup=row;
      this.all_messengers=this.editRow.messengers.map(item=>{
        if(item.provider==='topkee'){
          return {
            ...item,
            name:'website'
          };
        }
        return {
          ...item,
          name:item.provider+'|'+item.name
        };
      });
      this.editSubGroupMessengerVisible=true;
    },
    async handleEditSubGroupCares(row){
      this.editSubGroup=Object.assign({},row);
      if(!this.all_cares.length){
        const{ data } = await getGroupCares(this.editRow.id,{
          page:{
            number:1,
            size:1000
          },
          append:['name'],
        });

        this.all_cares=data;
      }

      // for (let i = 0; i < 100; i++) {
      //   this.all_cares.push({
      //     id:i+1000,
      //     name:i+"ren",
      //   })
      // }
      this.editSubGroupCareVisible=true;
    },

    async addTrustedDomain(){
      if(!this.addDomainRow.domain){
        this.$message.warning('请输入域名')
        return;
      }
      if(this.addDomainRow.id){
        await updateTrustedDomain(this.editRow.id,this.addDomainRow.id,this.addDomainRow)
      }else{
        await addTrustedDomain(this.editRow.id,this.addDomainRow);

      }

      this.addDomainVisible=false;
      this.refreshTrustedDomain();
    },

    async handleDeleteTrustedDomain(row){
      let that=this;
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await deleteTrustedDomain(that.editRow.id,row.id)
        that.refreshTrustedDomain();
      });

    },

    async handleEditTrustedDomain(row){
      this.addDomainRow={
        id:row.id,
        name:row.name,
        domain:row.domain,
      }
      this.addDomainVisible=true;
    },
    async refreshTrustedDomain(){
      this.page=1;
      this.total=0;
      this.getTrustedDomainList();
    },
    handleAddSubGroup(){
      this.addSubGroupRow={
        name:null,
      }
      this.addSubGroupVisible=true;
    },
    async refreshSubGroups(){
      this.pageSubGroup=1;
      this.totalSubGroup=0;
      this.getSubGroupList();
    },
    handleAddTrustedDomain(){
      this.addDomainRow={
        id:null,
        name:null,
        domain:null,
      }
      this.addDomainVisible=true;
    },
    alertPrivateConfirm() {
      this.$confirm(this.$t('care.messenger.terms.tips'), {
        showCancelButton: false,
        iconClass:'el-icon-warning',
      });
    },
    async onOpenThirdAuth(){


      // if(!this.addMessengerRow.private_checked){
      //   this.alertPrivateConfirm();
      //   return;
      // }
      if(this.edit_messenger_id){
        return;
      }
      const now = new Date();
      now.setHours(0, 0, 0, 0); // 设置时间为午夜
      const timestampOfToday = now.getTime();
      const cacheKey=`third_auth_src:${timestampOfToday}:${this.editRow.id}`;
      if(!this.third_auth_src){
        const cache=sessionStorage.getItem(cacheKey);
        if(cache){
          this.third_auth_src=cache;
        }else{
          const services=await getServices();
          const service=services.find(item=>item.code==='authing');
          if(service.subscribe!==1){
            await subscribeService(service.service_id);
          }
          const rs=await getServiceAdmin(service.service_id);
          this.third_auth_src=`${rs.link}&locale=${this.$i18n.locale}`;

          sessionStorage.setItem(cacheKey,this.third_auth_src);
        }

      }
      this.thirdAuthVisible=true;
    },
    async onOpenFacebookAuth(){
      // if(!this.addMessengerRow.private_checked){
      //   this.alertPrivateConfirm();
      //   return;
      // }
      if(this.edit_messenger_id){
        return;
      }
      const now = new Date();
      now.setHours(0, 0, 0, 0); // 设置时间为午夜
      const timestampOfToday = now.getTime();
      const cacheKey=`auth_facebook_auth_src:${timestampOfToday}:${this.editRow.id}`;

      if(!this.auth_facebook_auth_src){
        const cache=sessionStorage.getItem(cacheKey);
        if(cache){
          this.auth_facebook_auth_src=cache;
        }else{
          // const services=await getServices();
          // const service=services.find(item=>item.code==='facebook_auth');
          // if(service.subscribe!==1){
          //   await subscribeService(service.service_id);
          // }
          // const rs=await getServiceAdmin(service.service_id);
          if(!this.account_id){
            this.$message.warning('account_id not found');
            return;
          }
          this.auth_facebook_auth_src=`https://auth.topkee.com/productlinks?tenantId=432573278410769873&platform=facebook&product=FacebookComment&linkId=${this.account_id}&accountTenantId=${this.account_id}&locale=${this.$i18n.locale}`;

          sessionStorage.setItem(cacheKey,this.auth_facebook_auth_src);
        }

      }
      this.facebookAuthVisible=true;
    },
    async onOpenWabaAuth(){
      // if(!this.addMessengerRow.private_checked){
      //   this.alertPrivateConfirm();
      //   return;
      // }
      if(this.edit_messenger_id){
        return;
      }
      const now = new Date();
      now.setHours(0, 0, 0, 0); // 设置时间为午夜
      const timestampOfToday = now.getTime();
      const cacheKey=`auth_waba_src:${timestampOfToday}:${this.editRow.id}`;

      if(!this.auth_waba_src){
        const cache=sessionStorage.getItem(cacheKey);
        if(cache){
          this.auth_waba_src=cache;
        }else{
          const services=await getServices();
          const service=services.find(item=>item.code==='waba');
          if(service.subscribe!==1){
            await subscribeService(service.service_id);
          }
          const rs=await getServiceAdmin(service.service_id);
          const url=`${rs.link}&locale=${this.$i18n.locale}`;
          this.auth_waba_src=url.replace('/channel','/authing');

          sessionStorage.setItem(cacheKey,this.auth_waba_src);
        }

      }
      this.authWabaVisible=true;
    },
    async onProvider(){
      if(this.addMessengerRow.provider==='whatsapp'||this.addMessengerRow.provider==='messenger'){
        this.addMessengerRow.channel_id=null;
        this.tokens= await getTokens();
        if(this.addMessengerRow.provider==='messenger'&&this.addMessengerRow.auth_id){
          this.getFbPages(this.addMessengerRow.auth_id);
        }else if(this.addMessengerRow.provider==='whatsapp'){
          this.bizs=await getBusinessList(this.addBizRow.provider);
        }
      } else if(this.addMessengerRow.provider==='whatsapp:waba-channel-service'){
        this.getWabas();
      } else if(this.addMessengerRow.provider==='fbcomment'){
        // this.loadFacebookAuthPages();
        this.fb_comment_auths=await get_fbcomment_auths_api(this.account_id,this.addMessengerRow.provider);
      }
    },

    async loadFacebookAuthPages(){
      // this.add_messenger_loading=true;
      // // this.fbcomment_channels=await getFacebookAuthPages();
      // this.getFbPages(this.addMessengerRow.auth_id,'fbcomment_channels');
      // const rs = await getAuthToken(this.addMessengerRow.auth_id);
      // this.addMessengerRow.token=rs.access_token
      this.fb_comment_auths=await get_fbcomment_auths_api(this.account_id,this.addMessengerRow.provider);
      // this.add_messenger_loading=false;
    },
    async getWabas(){
      this.wabachannels=(await getWabaChannels()).data;
    },
    handleTpl(messenger){
      sessionStorage.setItem('group_id',messenger.group_id);
      sessionStorage.setItem('messenger_id',messenger.id);
      this.$router.push({ name: 'tpl', params: { messenger_id:messenger.id,group_id: messenger.group_id }});
    },
    async getFbPages(auth_id,fbpages='fbpages'){
      const rs = await getAuthToken(auth_id);
      console.log(rs.user_id,rs.access_token)
      let pages=[];
      let accounts = await fb_accounts(rs.access_token,rs.user_id);
      pages=pages.concat(accounts.data);
      while (accounts.paging?.cursors?.after){
         accounts = await fb_accounts(rs.access_token,rs.user_id,accounts.paging.cursors.after);
        pages=pages.concat(accounts.data);
      }
      this[fbpages]=pages;
    },
    async saveFormItems(){
      this.formItemLoading=true;
      try {
        await postFormItems(this.editRow.id,this.userform.formItems);
        this.getFromItemsData();
        this.formItemLoading=false;
        this.$message.success('ok');
      }catch (e) {
        this.formItemLoading=false;
        this.$message.error(e.message);
      }
      this.formItemVisible=false;

    },
    async getFromItemsData(){
      const rs= await getFormItems(this.editRow.id);
      this.userform.formItems=rs;
    },
    deleteFormItem(index){
      this.userform.formItems.splice(index,1);
    },
    addFormItem(){
      this.userform.formItems.push({
        "field": "change this",
        "label": "请修改",
        'val_type':'string',
      });
    },
    async handleCloseGreeting(greeting){
      const index=this.greetings.indexOf(greeting);
      if(index>-1){
        this.greetings.splice(index,1);
      }
      await deleteUserGreetings(this.editRow.id,greeting.id);
      this.apiGetUserGreetings();
    },
    async apiGetUserGreetings(){
      const rs=await getUserGreetings(this.editRow.id);
      this.greetings=rs;
    },
    async handleGreetingConfirm(){
       if(!this.addGreetingValue||!trim(this.addGreetingValue)) return;
       const greeting=trim(this.addGreetingValue);
       // this.greetings.push({
       //   id:new Date().getTime(),
       //   greeting,
       // });
      this.addgreetingLoading=true;
      try {
        await saveUserGreetings(this.editRow.id,greeting);
        this.addGreetingValue=null;
        this.addGreetingVisible=false;
        this.addgreetingLoading=false;
        this.apiGetUserGreetings();
      }catch (e) {
        this.addgreetingLoading=false;
        throw e;
      }


    },
    initWebHook: function (editRow) {
      if (editRow.webhook) {
        this.webhook = Object.assign(this.webhook, editRow.webhook)
        if(editRow.webhook.enable_custom_fields){
          this.webhook.form_items=JSON.parse(editRow.webhook.form_items);
        }else{
          this.webhook.form_items=defHook.form_items;
        }
      }else {
        this.webhook =Object.assign({},defHook);
      }
    },
    onDeleteWebhook(){
      const that=this;
      this.$confirm(this.$t('care.webhook.delete'), this.$t('care.tips'), {
        confirmButtonText: this.$t('care.delete'),
        cancelButtonText: this.$t('care.cancel'),
        type: 'warning'
      }).then(async () => {
        await deleteWebhook(that.editRow.id);
        this.webhook=Object.assign({},defHook);
        const editRow={
          ...this.editRow,
          webhook:Object.assign({},defHook)
        };
        this.$emit('onChangeEditRow',editRow);
      });
    },
    async onSetWebhook(){
      try {
        if(!this.webhook.url||!this.webhook.token){
          this.$message.warning(this.$t('care.messenger.messenger_add_parms'));
          return;
        }
        const rs=await setWebhook(this.editRow.id,this.webhook.url,this.webhook.token,this.webhook.enable_custom_fields,this.webhook.form_items);
        if(rs['result']){
          this.$message.success('success');
          const webhook= this.webhook;
          webhook.verify=1;
          webhook.state=1;
          this.webhook=Object.assign(this.webhook,webhook);
          const editRow={
            ...this.editRow,
            webhook
          };
          this.$emit('onChangeEditRow',editRow);
        }else{
          this.$message.error(this.$t('care.webhook.bind_err'));
        }
      }catch (e) {
        this.$message.error(this.$t('care.webhook.bind_err'));
      }

    },
    getCustomizeLang(){
      let customize_lang={
        open_messenger_txt:'開啟網頁對話'
      };
      switch (this.script_lang) {
        case "zh_HK"||'zh_TW':
          customize_lang={
            open_messenger_txt:'開啟網頁對話'
          };
          break;
        case "zh_CN":
          customize_lang={
            open_messenger_txt:'开启网页对话'
          };
          break;
        case "en":
          customize_lang={
            open_messenger_txt:'Chat On Web'
          };
          break;
      }
      return customize_lang;
    },
    handleCommand(command,sub=null){
      let sub_id='';
      if(sub?.id){
        sub_id=sub.id;
      }
      this.script_sub_id=sub_id;
      this.script_lang=command;
      this.onCopyScript();
    },
    onCopyScript(){
      var oScript = document.createElement('script');
      // oScript.type = 'text/javascript';
      oScript.innerText='(function(n,o,t,i,f){n[i]={};var m=["init","setOpts"];n[i]._c=[];m.forEach(me=>n[i][me]=function(){n[i]._c.push([me,arguments])});var elt=o.createElement(f);elt.type="text/javascript";elt.async=true;elt.src=t;var before=o.getElementsByTagName(f)[0];before.parentNode.insertBefore(elt,before)})(window,document,"https://care-admin.topkee.top/embed/embed.umd.min.js","tkcare","script");tkcare.init({group_id:"'+this.editRow.id+'",script_sub_id:"'+this.script_sub_id+'",locale:"'+this.script_lang+'",customize_lang:{open_messenger_txt:"'+this.getCustomizeLang().open_messenger_txt+'"}});';

     this.copy(oScript.outerHTML);
     this.$message.success('down')
    },
    setEmbedOption: function () {
      if (this.editRow.embed_option) {
        let embed_option = this.editRow.embed_option;
        if (typeof this.editRow.embed_option === 'string') {
          embed_option = JSON.parse(this.editRow.embed_option)
        }
        this.embedOption = embed_option;
      } else {
        this.embedOption = defaultEmbedOption
      }
    },
    onOpenEmbedOption(){
      this.setEmbedOption();
      this.settingScriptVisible=true;
    },
    async saveEmbedOption(){
      const editRow={
          ...this.editRow,
          embed_option:this.embedOption
      };
      this.$emit('onChangeEditRow',editRow);
      await  updateGroup(this.editRow.id,editRow);
    },
    onClose(){
      this.$emit("onClose");
    },
    toggleColorPicker(val=null){
      if(val){
        this.showColorPicker=val;
      }else {
        this.showColorPicker=!this.showColorPicker;
      }
    },
    getColor(row){
      if(row?.colors?.rgba){
        const rbga=row?.colors?.rgba;
        const color = `rgba(${rbga.r},${rbga.g},${rbga.b},${rbga.a})`;
        return color;
      }

      return '';
    },
    async onChangeEnable(){
     const messenger_id= this.edit_messenger_id;
     const enable= this.addMessengerRow.enable?1:0;
     const group_id=this.addMessengerRow.group_id;
     await putMessengerEnable(group_id,messenger_id,enable);
     const num = this.editRow.messengers.findIndex(item=>item.id === this.edit_messenger_id);
     if(num){
       this.editRow.messengers[num].enable=enable;
       this.$emit('onChangeEditRow',{
         ...this.editRow
       })
     }

    },
    async onEditMessenger(messenger){
     console.log('onEditMessenger')
     let provider=messenger.provider
     if(messenger.auth_provider==="waba"){
       provider=`${messenger.provider}:waba-channel-service`
       this.onProvider();
     }
     this.addMessengerRow={
       ...this.addMessengerRow,
       provider:provider,
       name:messenger.name,
       auth_id:messenger.auth_id,
       group_id:messenger.group_id,
       enable:messenger.enable?true:false,
     }
     if(messenger.provider === 'topkee'){
       return;
     }else if(provider === 'messenger'){
        let page_id=messenger.conf_identify;
        this.addMessengerRow.page_id=page_id;
        this.addMessengerVisible=true;
      }else if(provider=== 'whatsapp'){
        this.provider_whatsapp_visiable=true;
        let phone_number_id=messenger.conf_identify;
        this.addMessengerRow.business_id=messenger.business_id;
        this.addMessengerRow.phone_number_id=phone_number_id;
      }else if(provider=== 'whatsapp:waba-channel-service'){
        let phone_number_id=messenger.conf_identify;
        this.addMessengerRow.channel_id=messenger.auth_id;
        this.addMessengerRow.phone_number_id=phone_number_id;
      }else if(provider === 'fbcomment'){
       let page_id=messenger.conf_identify.split(':')[0];
       this.addMessengerRow.channel_id=page_id;
     }
     this.edit_messenger_id=messenger.id;
     this.edit_messenger=messenger;
     this.addMessengerVisible=true;
    },
    async handleDeleteMessenger(messenger){
      let that = this;
      this.$confirm(
          this.$t('care.messenger.messenger_delete_tip')
          ,this.$t('care.messenger.messenger_delete_title')
          ,{
            type:'warning',
            cancelButtonText:this.$t('care.messenger.cancel'),
            confirmButtonText: this.$t('care.messenger.messenger_delete_force')
          }
      ).then(async ()=>{
        let editRow=that.editRow
        remove(editRow.messengers,item=>item===messenger);
        // that.editRow={...editRow  };
        this.$emit('onChangeEditRow',{
          ...editRow
        })
        await deleteMessenger(messenger.group_id,messenger.id);
        that.$message.success('success')
      });
    },
    updateColor(){
      this.changeLimit(this.editRow)
    },
    async addMessenger(){
      if(this.edit_messenger_id){
        this.addMessengerVisible=null;
        return;
      }
      const addMessengerRow=this.addMessengerRow;
      addMessengerRow.group_id=this.editRow.id;
      console.log(111,addMessengerRow)
// return;
//       // eslint-disable-next-line no-unreachable
      if(addMessengerRow.provider==='whatsapp'){
        if(!addMessengerRow.business_id
            ||!addMessengerRow.auth_id
            ||!addMessengerRow.token
            ||!addMessengerRow.phone_number_id
            ||!addMessengerRow.group_id
        ) {
          this.$message.warning(this.$t('care.messenger.messenger_add_parms'));
          return;
        }
        try {
          const messenger = await  postAddMessenger(addMessengerRow.provider,
              addMessengerRow.business_id,
              addMessengerRow.phone_number_id,
              addMessengerRow.auth_id,
              addMessengerRow.token,
              addMessengerRow.group_id,
              addMessengerRow.name,
          );
          let editRow=this.editRow;
          remove(editRow.messengers,(item)=>item.phone_number_id==messenger.conf_identify);
          editRow.messengers.push(messenger);
          // this.editRow=editRow;
          this.$emit('onChangeEditRow',{
            ...editRow
          })

          this.addMessengerVisible=false;
        }catch (e) {
          console.log(e)
          this.$message.warning(e.data.message);

        }


      }
      else if(addMessengerRow.provider==='messenger'){
        try {
          const messenger = await  postAddMessenger(addMessengerRow.provider,
              addMessengerRow.business_id,
              addMessengerRow.phone_number_id,
              addMessengerRow.auth_id,
              addMessengerRow.token,
              addMessengerRow.group_id,
              addMessengerRow.name,
              addMessengerRow.page_id,
          );
          let editRow=this.editRow;
          editRow.messengers.push(messenger);
          // this.editRow=editRow;
          this.$emit('onChangeEditRow',{
            ...editRow
          })

          this.addMessengerVisible=false;
        }catch (e) {
          console.log(e)
          this.$message.warning(e.data.message);

        }
      }
      else if(addMessengerRow.provider==='whatsapp:waba-channel-service'){
        try {
            const messenger = await  postAddMessenger(addMessengerRow.provider,
                addMessengerRow.business_id,
                addMessengerRow.phone_number_id,
                addMessengerRow.auth_id,
                addMessengerRow.token,
                addMessengerRow.group_id,
                addMessengerRow.name,
                addMessengerRow.page_id,
                addMessengerRow.channel_id,
            );
            let editRow=this.editRow;
            editRow.messengers.push(messenger);
            // this.editRow=editRow;
            this.$emit('onChangeEditRow',{
              ...editRow
            })

            this.addMessengerVisible=false;
        }catch (e) {
            console.log(e)
            this.$message.warning(e.data.message);

        }
      }else if(addMessengerRow.provider==='fbcomment'){
        try {
          // addMessengerRow.auth_id=addMessengerRow.channel_id;
          // addMessengerRow.token=(await getFacebookAuthPageToken(addMessengerRow.page_id))[0]['access_token'];
          // console.log(addMessengerRow)
          // return;
          const messenger = await  postAddMessenger(addMessengerRow.provider,
              addMessengerRow.business_id,
              addMessengerRow.phone_number_id,
              addMessengerRow.auth_id,
              addMessengerRow.token,
              addMessengerRow.group_id,
              addMessengerRow.name,
              addMessengerRow.page_id,
              addMessengerRow.channel_id,
          );
          let editRow=this.editRow;
          editRow.messengers.push(messenger);
          // this.editRow=editRow;
          this.$emit('onChangeEditRow',{
            ...editRow
          })

          this.addMessengerVisible=false;
        }catch (e) {
          console.log(e)
          message.warning(e.data.message);

        }
      }

    },
    async handleCloseEmail(delItem){
      let editRow=this.editRow;
      let emails=editRow.emails;
      remove(emails,item=>item==delItem)
      editRow.emails=emails

      this.$emit('onChangeEditRow',{
        ...editRow
      })

      await delmail(editRow.id,delItem);
    },
    showInput(){
      this.addMailInputVisible=true;
    },
    async addBiz(){
      if(!this.addBizRow.business_id
          ||!this.addBizRow.business_name){
        return;
      }
      await addBusiness( this.addBizRow.provider, this.addBizRow.business_id, this.addBizRow.business_name);
      this.bizs=await getBusinessList(this.addBizRow.provider);
      this.addBizVisible=false;
      this.addBizRow.business_id=null;
      this.addBizRow.business_name=null;

    },
    async onAddMessenger(){
      this.edit_messenger_id=null;
      this.edit_messenger=null;
      this.addMessengerRow={
            provider:'whatsapp:waba-channel-service',
            business_id:'',
            name:'',
            auth_id:'',
            token:'',
            phone_number_id:null,
            group_id: null,
            page_id: null,
            channel_id: null,
            auth_provider: null,
            enable:1,
      };
      this.addMessengerVisible=true;
    },
    async onAddBiz(){
      this.addBizVisible=true;

    },
    async handleInputConfirm(){
      if(this.addMailInputValue&&this.editRow.emails.indexOf(this.addMailInputValue)==-1){
        const  editRow=this.editRow;
        editRow.emails.push(this.addMailInputValue);
        this.$emit('onChangeEditRow',{
          ...editRow
        })

        this.addMailInputVisible=false;
        try {
          await addmail(this.editRow.id,this.addMailInputValue);
        }catch (res) {
          message.error(res.data.message)
          return;
        }
        this.addMailInputValue=null;

      }
    },
    async changeLimit(group){

      let colors=null;
      if(group.colors &&typeof group.colors !=='string') {
        colors=JSON.stringify(group.colors)
      }
      console.log('changeLimit')
      await  updateGroup(group.id,{
        ...group,
        colors,
        embed_option:this.embedOption
      });
    },
    throttle(fn,delay,minTime) {
      if (typeof fn !== 'function') return new Function()
      let timer = null;
      let starTime = null;
      return function () {
        let now = +new Date();
        !starTime && (starTime = now);
        if (minTime && now - starTime > minTime) {
          fn();
          starTime = now;//将开始时间重置
        } else {
          clearTimeout(timer);
          timer = setTimeout(function () {
            fn();
            starTime = null;
            clearTimeout(timer);
          }, delay);

        }
      }
    },
  },
}
</script>
<style lang="less" >
.auth_btn{
  color:white;
  cursor: pointer;
  padding:0 8px;
  background-color: #409EFF;

}
.auth_btn_disable{
  background-color: rgb(245,247,250);
  color: #C0C4CC;

}
.waba{

  .el-dialog__header{
    display: none !important;
  }
  .el-dialog__body{
    padding: 0;
  }
}
.waba_select{
  .el-input__prefix{
    left: 0 !important;
  }
  .el-input__inner{
    border-radius: 0;
  }
}

</style>
<style lang="less" scoped>

/deep/ .el-transfer-panel{
  width: 300px !important;
}
.setting{
  //max-height: calc( 85vh - 200px );
  //min-height: 520px;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  padding-right: 20px;
  &::-webkit-scrollbar {display:none}

}
.formval{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 40px;
  strong{
    display: inline-block;
    margin-left: 10px;
  }
  .switch{
    margin-left: 20px;
    margin-right: 5px;
  }
  .greetings-wrap{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .greetings{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 15px;
      flex-wrap: wrap;
      .el-tag{
        margin-top: 10px;
        margin-right: 10px;
        height: fit-content;
        white-space: normal;
      }
    }
  }

}
.script_copy_warp{
  margin-left: 20px;
  .script_copy_left{
    width: 150px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .script_copy_right{
    width: 200px;
    /deep/ .el-input__inner{
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: 0;
      &:focus{
        border-color: #C0C4CC;
      }
    }
  }
}
</style>