import request from "@/libs/utils/request";
import qs from "qs";
import parse from "@/libs/utils/QueryParser";
import {QueryModel} from "@/libs/models";

export const postAddMessenger =(provider:string,business_id:string,phone_number_id:string,auth_id:string,token:string,group_id:string,name:string,page_id:string,channel_id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/messenger`,
        method: 'post',
        data:{
            provider,
            business_id,
            phone_number_id,
            auth_id,
            group_id,
            name,
            token,
            page_id,
            channel_id,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const putMessengerEnable =(group_id:string,id:string,enable:1|0):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/messenger_enable/${group_id}/${id}`,
        method: 'put',
        data:{
            enable,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const deleteMessenger =(group_id:string,id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/messenger/${id}`,
        method: 'delete',
        data:{
            group_id,
        }
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const getChatAddr =(messenger_id:string,identity?:string,from_source?:string):string => {
   const query=qs.stringify({
        messenger_id,
        identity,
        from_source,
    })
    const api=`/front_api/messenger/chat_addr?${query}`;
   return api
};

export const getMessengerTpls =(messenger_id:string,query?:QueryModel):Promise<any> => new Promise((resolve, reject) => {
    const str= parse(query)
    request({
        url: `admin_api/messenger/${messenger_id}/tpls${str}`,
        method: 'get',
    }).then((response: any) => {
        resolve(response.data.data);
    }).catch((e) => reject(e));
});

export const pullMessengerTpls =(messenger_id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/messenger/${messenger_id}/pull_tpls`,
        method: 'post',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});

export const addMessengerTpl =(messenger_id:string,data):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/messenger/${messenger_id}/tpls`,
        method: 'post',
        data:data
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const updateMessengerTpl =(messenger_id:string,id:string,data):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/messenger/${messenger_id}/tpls/${id}`,
        method: 'put',
        data:data
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});
export const deleteMessengerTpl =(messenger_id:string,id:string):Promise<any> => new Promise((resolve, reject) => {
    request({
        url: `admin_api/messenger/${messenger_id}/tpls/${id}`,
        method: 'delete',
    }).then((response: any) => {
        resolve(response.data);
    }).catch((e) => reject(e));
});